
  

  
  
  .next {
    border: #008080;
    background-color: #008080;
    color: #fff;
    width: 25%;
    margin-top: 19px;
    border-radius: 5px;
    height: 30px;
  }
  
  .button-check {
    border: #008080;
  }
  
  .button1 {
    position: relative;
    /* top: 50%; */
    width: 110px;
    height: 40px;
    margin: -20px auto 0 auto;
    overflow: hidden;
    border: 1px solid #797979;
    border-radius: 15px;
    height: 42px;
    margin-right: 2px;
  }
  
  .button.r,
  .button.r .layer {
    border-radius: 100px;
  }
  
  .new-class-toggle {
    width: 90px !important;
    /* margin-right: 1px; */
    height: 100%;
    margin-top: 2px !important;
    /* border-color: black; */
    /* border-width: 2px; */
    margin-right: -2px;
    margin-top: 0px !important;
    border: 0.2px solid #797979 !important;
    border-width: 0.5px !important;
    border-radius: 25px !important;
    background-color: rgb(255, 255, 255) !important;
  }
  
  a {
    color: #040404;
    text-decoration: none;
  }
  
  
  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }
  
  .knobs {
    z-index: 2;
  }
  
  .layer {
    width: 100%;
    background-color: #ebf7fc;
    transition: 0.3s ease all;
    z-index: 1;
  }
  
  .frloo {
    /* display: inline-table !important; */
    /* vertical-align: middle !important; */
    float: right;
    margin-top: -5px;
  }
  
  .frloo ion-icon {
    margin-right: 5px !important;
    vertical-align: middle !important;
    font-size: 15px !important;
  }
  
  .frloo span {
    margin-right: 8px;
  }
  
  .setting-text {
    margin-top: 5px;
    line-height: 0.9;
    padding-bottom: -2px;
  }
  
  .setting-text p {
    margin-left: 15px;
  }
  
  /* Button 10 */
  #button-10 .knobs:before,
  #button-10 .knobs:after,
  #button-10 .knobs span {
    position: absolute;
    top: 4px;
    width: 30px;
    height: 10px;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
    font-family: Helvetica-Bold;
    border-radius: 2px;
    transition: 0.3s ease all;
  }
  
  #button-10 .knobs:before {
    content: " ";
    /* left: 20px; */
    margin-top: -4px;
    height: 25px;
    width: 70px;
    border-radius: 18px;
    color: #fff;
    background-color: #07090c;
    letter-spacing: 1px;
    font-family: Helvetica-Bold
  }
  
  .like-button {
    background-color: #fff;
    width: max-content;
  }
  
  #button-10 .knobs:after {
   
      content: "People";
      right: 20px;
      color: black;
      margin-top: -7px;
      letter-spacing: 0.5px;
      text-align: center;
 
  }
  
  #button-10 .knobs span {
    display: inline-block;
    left: 30px;
    color: #fff;
    z-index: 1;
    text-align: center;
    margin-top: -5px;
    font-family: Helvetica;
  }
  
  .navbar-right1 {
    margin-top: -28px;
    margin-left: 73px;
  }
  
  #button-10 .checkbox:checked+.knobs span {
    color: #4e4e4e;
  }
  
  #button-10 .checkbox:checked+.knobs:before {
    left: 97px;
    color: #fff;
    background-color: #07090c;
  }
  
  #button-10 .checkbox:checked+.knobs:after {
    color: #fff;
  }
  
  #button-10 .checkbox:checked~.layer {
    background-color: #fcebeb;
  }
  
  .search-outer-container {
    border: 0.5px solid #797979;
    border-radius: 20px;
    height: 45px;
    border-style: solid;
  }
  
  .searchbox {
    border: none;
    border-radius: 20px;
    font-weight: 400;
    font-family: Helvetica, FontAwesome;
  }
  
  .border-bottom-0,
  .border-top,
  .border-bottom,
  .border-end {
    border-color: #efefef !important;
  }
  
  .moreprof p {
    text-align: center;
    margin: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
  
  }
  
  .moreprof-submit {
    border-radius: 8px;
    margin-top: 50px;
    width: 30%;
    color: #fff;
    border-color: #008080;
    background-color: #008080;
    border-style: solid;
    font-family: Helvetica;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  
  .moreprof-learn {
    border-radius: 8px;
    background-color: #040404;
    color: #fff;
    border-style: solid;
    width: 50%;
    margin-bottom: 20px;
    font-family: Helvetica;
    padding-bottom: 3px;
    padding-top: 3px;
  }
  
  .moreprof-advance h2 {
    color: #008080;
    font-family: Helvetica;
    font-weight: 600;
    line-height: 25px;
    font-size: 27px;
  }
  
  .order-detail-profile-icon {
    float: right !important;
    display: flex;
    margin-top: 10px;
    margin-right: 10px;
    font-size: 10px !important;
    font-weight: 700;
  }
  
  .end-border {
    border-color: #040404 !important;
  }
  
  .project-details p {
    font-family: Helvetica;
  }
  
  .switch {
    margin-right: 20px;
    border-radius: 5px;
    width: 105px;
    color: #008080;
    border-color: #008080;
    background-color: #fff;
    height: 30px;
    border-width: 0.8px;
    font-size: small;
    font-size: 11px;
    border-style: solid;
    font-weight: 500;
    font-family: Helvetica-Bold;
  
  }
  
  /* .chh {
    font-weight: 500;
  } */
  
  /* .naaa:active{
  font-weight: 900 !important;
  color: #7e8829 !important;
  } */
  .side-link:active {
    color: #008080 !important;
    font-size: 800;
  }
  
  .chhh {
    margin-top: 10px;
    margin-bottom: 13 px;
  }
  
  .chhh ion-icon {
    margin-top: 10px;
  }
  
  .hire {
    margin-right: 0px;
    border-radius: 5px;
    width: 105px;
    background-color: #008080;
    border-style: none;
    border-color: #008080;
    color: #fff;
    border-style: solid;
    height: 30px;
    font-size: small;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 1px;
    font-family: Helvetica-Bold;
  }
  .fZmSnF{
    background-color: #fff !important;
  }
  .containerhover {
    position: relative;
  }
  
  .containerhoverimage {
    display: block;
    width: 97%;
    height: auto;
    border-radius: 5px;
    max-height: 100%;
    min-height: 100%;
  }
  
  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease;
  }
  
  .containerhover:hover .overlay {
    height: 20%;
  }
  
  .containerhovertext {
    color: black;
    font-size: 12px;
    position: absolute;
    top: 5%;
    left: 1%;
    /* -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); */
  
  }
  
  .model-textt {
    text-align: end;
  }
  
  .bPQHAq .head-div .icon-suffix {
    display: none !important;
    cursor: none !important;
  }
  
  .switchaccount-img {
    margin-top: 70px;
    margin-bottom: 20px;
  }
  
  .switch-text {
    color: #008080;
    margin-bottom: 8px;
  }
  
  .switch-button {
    margin-top: 20px;
    width: 50%;
    background-color: #07090c;
    color: #fff;
    border-radius: 5px;
    margin-bottom: 100px;
  }
  
  .hire-button {
    margin-top: 40px;
    background-color: #008080;
    width: 40%;
    color: #fff;
    border-style: none;
    border-radius: 5px;
  }
  
  .hire-text {
    margin-top: 30px;
    margin-left: 15px;
  }
  
  .hire-upgared {
    margin-top: 40px;
    width: 40%;
    border-radius: 5px;
    color: black;
    background-color: #ffd47c;
    border-style: none;
    margin-bottom: 20px;
  }
  
  .hiretalent-table-card {
    background-image: linear-gradient(to right, #ffffff, #e8edf6, #caddeb, #a7cfda, #87c1c1);
  }
  
  .no-order {
    width: 90%;
    height: 45px;
    border-radius: 20px;
    margin-bottom: 30px;
    background-image: linear-gradient(to right, #ff5566, #c67724, #7e8829, #3a895a, #008080);
  }
  
  .no-order p {
    padding-top: 5px;
    color: #fff;
    font-weight: 900;
    margin-left: -1px;
    font-size: 18px;
    font-family: Helvetica-Bold;
  }
  
  .no-order-button {
    border-radius: 8px;
    background-color: #fff;
    border-style: none;
    margin-top: 5px;
    margin-left: 20px;
    font-size: small;
    width: 25%;
    height: 25px;
  }
  
  .order-details-requirements {
    border-radius: 5px;
    background-color: #fff;
    width: max-content;
  }
  
  .order-details-shortlisted {
    border-radius: 5px;
    background-color: #fff;
    width: max-content;
    float: right;
  }
  
  .order-details-shortlisted ion-icon {
    font-size: 12px !important;
  }
  
  .order-details-requirements ion-icon {
    font-size: 12px !important;
  }
  
  .message-post {
    margin-top: 20px;
    border-radius: 8px;
    width: 60%;
    color: #008080;
    border-color: #008080;
    border-style: solid;
    border-width: 1px;
    background-color: #fff;
    font-family: Helvetica-Bold;
  }
  
  .no-message h4 {
    font-family: Helvetica-Bold;
    font-size: 19px;
    color: #008080;
    margin-top: 80px;
    font-weight: 600;
  }
  
  .no-message-show h4 {
    font-size: 25px;
    font-family: Helvetica-Bold;
    margin-top: 50px;
  }
  
  .no-message-show ion-icon {
    font-size: 60px !important;
    margin-top: 20px;
  }
  
  .order-details-img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }
  
  .order-details-container {
    display: flex;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 5px;
    background-color: #efefef;
  }
  
  .order-details-container-full div {
    width: 70%;
    float: left;
  
  }
  
  
  .rs-sidenav-nav>.rs-dropdown .rs-dropdown-toggle, .rs-sidenav-nav>.rs-sidenav-item{
    background-color: #1f263b !important;
  }
  .rs-sidenav-default .rs-sidenav-toggle-button {
    background-color: #1f263b !important;
  }


  #form1:required:focus:valid,
  #form1:required:invalid {
    outline: none;
    /* border-color: blue; Keep the border color blue */
  }
  
  #form1:required:focus:invalid {
    outline: none;
    border-color: red; /* Change the border color to red */
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25); /* Change the box shadow to red */
  }