.appbar {
    background-color: white !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05) !important;
    /* position: fixed !important; */
    /* left: 65px !important; */
}

.MuiToolbar-root {
    padding-right: 0px !important;
}

.drop-nav {
    height: 6vh;
    margin-top: 12px;
}

.listStyle {
    margin-top: 277px;
    position: absolute;
    right: 0px;
}

.form-select {
    transition-delay: 250ms;
    transition-property: margin-right;

}
.ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 135px !important;
}
.logout_btn{
  background-color: #18285a !important;
  border: none !important;
  border-radius: 5px !important;
}

.line {
    width: 100%;
    height: 4rem;
    overflow: hidden;
    border: 1px solid black;
    padding: 0;
    margin-bottom: 16px;
  }
  
  /* flipping class and key frames*/
  .flipX {
    animation: 1s anim-flipX ease infinite;
  }
  @keyframes anim-flipX {
    0% {
      opacity: 0;
      transform: rotateX(90def);
    }
    50% {
      opacity: 1;
      transform: rotateX(720deg);
    }
    100% {
      /* animate nothing to pause animation at the end */
      opacity: 1;
      transform: rotateX(720deg);
    }
  }
  
  /* lineup class and keyframes */
  .lineUp {
    animation: 2s anim-lineUp ease-out infinite;
  }
  @keyframes anim-lineUp {
    0% {
      opacity: 0;
      transform: translateY(80%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  /* flipY class and keyframes*/
  .flipY {
    animation-name: spin, depth;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 6s;
  }
  @keyframes spin {
    0% {
      transform: rotateY(0deg);
    }
    100% {
      transform: rotateY(-360deg);
    }
  }
  @keyframes depth {
    0% {
      text-shadow: 0 0 black;
    }
    25% {
      text-shadow: 1px 0 black, 2px 0 black, 3px 0 black, 4px 0 black, 5px 0 black;
    }
    50% {
      text-shadow: 0 0 black;
    }
    75% {
      text-shadow: -1px 0 black, -2px 0 black, -3px 0 black, -4px 0 black,
        -5px 0 black;
    }
    100% {
      text-shadow: 0 0 black;
    }
  }
  
  /* subtle zoom to attention and then back */
  .pop-outin {
    animation: 2s anim-popoutin ease infinite;
  }
  
  @keyframes anim-popoutin {
    0% {
      color: black;
      transform: scale(0);
      opacity: 0;
      text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
    25% {
      color: red;
      transform: scale(2);
      opacity: 1;
      text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
    }
    50% {
      color: black;
      transform: scale(1);
      opacity: 1;
      text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      /* animate nothing to add pause at the end of animation */
      transform: scale(1);
      opacity: 1;
      text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
    }
  }
  
  .fromtop {
    animation: 2s anim-fromtop linear infinite;
  }
  @keyframes anim-fromtop {
    0% { opacity: 0; transform: translateY(-100%);}
    25% { opacity: 1; transform: translateY(0%);}
    50% {
    }
    100% {
    }
  }