body {
  font-family: Arial, sans-serif;
}

.table-container {
  width: 31cm; 
  margin: 0 auto; /* Center the table horizontally */
  padding: 1cm; /* Add padding to match typical margin sizes */
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #000; /* Add borders to table cells */
  padding: 3px; /* Add padding to table cells */
  font-size: 14px;
}

.table th {
  background-color: #f2f2f2; /* Background color for table header */
}


@media print {
  body {
    margin: 0;
    padding: 0;
  }

  .table-container {
    margin: 0;
    padding: 0;
  }

  .no-print {
    display: none; /* Hide non-printable elements */
  }
}

@media print {
  #print-content {
    width: 210mm;
    height: 297mm;
    margin: auto;
    page-break-inside: avoid;
  }
}



.toggle {
  position: relative;
  box-sizing: border-box;
  float: right;
}
.toggle input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.toggle label {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.toggle label:before {
  content: '';
  width: 50px;
  height: 17px;
  background: #ccc;
  position: relative;
  display: inline-block;
  border-radius: 46px;
  box-sizing: border-box;
  transition: 0.2s ease-in;
}

.toggle label:after {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  left: 2px;

  z-index: 2;
  background: #fff;
  box-sizing: border-box;
  transition: 0.2s ease-in;
}
.toggle input[type="checkbox"]:checked + label:before {
  background: #28036d;
}
.toggle input[type="checkbox"]:checked + label:after {
  left: 35px;
}
.table-attendance td{
  width: 110px;
  margin-left: 10px;
}
.dropdown-toggle:after {
  border: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.userproject{
  margin-left: 20px;
  float: left;
  background-color: #fff;
  border-style: none;
  width: 100px;
}
.project-button{
  background-color: black !important;
  color: #fff;
  border-radius: 20px;
  margin-left: 20px;
  white-space: nowrap;
  width: 100px;
}
/* .sc-dmctIk {
  min-height : 400px;
  } */
  .dApqnJ {
    min-height : 600px !important;
    }
.navvitem:hover{      
  color: #797373 !important;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
  background-color:#18285a !important;
  color: #fff !important;
}
.nav-link{
  color: black !important;
}
.nav-link:active{
  color: rgb(177, 104, 104) !important;
}
.staffview-active:active{
  color: rgb(156, 69, 69) !important;
}
.inputAdvance{
  width:  10%;
}
.nav-fill .nav-item .nav-link{
  background-color: lightgray;

}
.nav-fill .nav-item{
margin: 2px;
}
.Fgnmg  {
  display: none !important;
  position: relative !important;
  /* top: 0; */
  /* left: 0; */
  /* width: 100%; */
  box-sizing: inherit;
  /* z-index: 1; */
  /* -webkit-align-items: center; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #e3f2fd;
  font-size: 18px;
  font-weight: 400;
  color: rgba(0,0,0,0.87);
  padding-left: 16px;
  padding-right: 8px;
  -webkit-transform: translate3d(0,-100%,0);
  -ms-transform: translate3d(0,-100%,0);
  transform: translate3d(0,-100%,0);
  -webkit-transition-duration: 125ms;
  transition-duration: 125ms;
  -webkit-transition-timing-function: cubic-bezier(0,0,0.2,1);
  transition-timing-function: cubic-bezier(0,0,0.2,1);
  will-change: transform;
  -webkit-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);}
  .sc-fnGiBr {
    display: none !important;
    position: relative !important;
    /* top: 0; */
    /* left: 0; */
    /* width: 100%; */
    box-sizing: inherit;
    /* z-index: 1; */
    /* -webkit-align-items: center; */
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    background-color: #e3f2fd;
    font-size: 18px;
    font-weight: 400;
    color: rgba(0,0,0,0.87);
    padding-left: 16px;
    padding-right: 8px;
    -webkit-transform: translate3d(0,-100%,0);
    -ms-transform: translate3d(0,-100%,0);
    transform: translate3d(0,-100%,0);
    -webkit-transition-duration: 125ms;
    transition-duration: 125ms;
    -webkit-transition-timing-function: cubic-bezier(0,0,0.2,1);
    transition-timing-function: cubic-bezier(0,0,0.2,1);
    will-change: transform;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);}



   