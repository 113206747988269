.login-container {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    padding: 1rem;
}

.image-container {
    height: 94vh;
}

.image-container img {
    max-width: 100%;
    max-height: 100%;
}

.form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 6rem 0rem 0rem;
}

.form-content {
    text-align: start;
}

input {
    width: 90%;
    padding: 0.8rem;
    margin-top: 20px;
}

.send-button {
    width: 91%;
    padding: 0.8rem 0;
    background: #18285b;
    border-radius: 5px;
    text-align: center;
    color: white;
    margin-top: 20px;
    cursor: pointer;
}

.contact-support {
    margin-top: 20px;
    text-align: center;
    color: rgb(157, 156, 156);
}
.contact-support span{
    font-weight: bold;
    color: #18285b;  
}
.qr-staff-text{
    font-size: 35px;
    color: #18285b;
}
.login-text{
    margin-top: 10px;
    font-size: 25px;
    font-weight: bold;
    color: #18285b;
}
.enter-number-text{
    margin-top: 20px;
    color: rgb(157, 156, 156);
}
.send-otp-text{
    color: rgb(157, 156, 156);
}


@media (max-width: 820px) {
    .image-container {
        height: 35vh;
    }
    .form-container {
        padding: 0rem;
    }

}


@media (max-width: 810px) {
    .login-container {
        grid-template-columns: 1fr; 
    }
    .image-container{
        height: 55vh;
    }
    .form-container {
        padding: 0rem;
    }
    .qr-staff-text{
      text-align: center;
    }
    .login-text{
      text-align: center;
    }
}


.form-control:focus {
    border-color: #ced4da; /* Set the border color to your desired color */
    box-shadow: none; /* Remove the box-shadow */
  }