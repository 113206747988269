.dropdown button{
  
    background-color: white !important;
    color: black !important;
    /* /* display: flex !important; */
    justify-content: space-between !important;
    align-items: center !important; 
    border: 3px solid gray  !important;
}
.dropdown button:hover{
  border: 3px solid #18285A  !important;
    
}
.cutum-data{
    
  background-color: white !important;
  color: black !important;
  /* /* display: flex !important; */
  position: relative;
  display: inline-block;
  overflow: hidden;
  max-width: 150px; 
  border-radius: 8px;
  border: 3px solid gray  !important;
  padding: 5px;
}
.cutum-data:hover{
  border: 3px solid #18285A  !important;
  border-radius: 8px;
}