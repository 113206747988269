.marked-status{
    margin-bottom: 0px;
    padding: 3px;
}
.timelog-p{
    font-size: 12px;
}
.view_notice{
    background-color: #18285a !important;
  border: none !important;
  border-radius: 5px !important;
}