.modal-margin-top {
    margin-top: 68px;
}

.MdbSearchInput {
    /* position: relative; */
    /* bottom: 56px; */
}

/* .WQNxq {
    position: relative;
    width: 100%;
    border-radius: inherit;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 0;
    height: 45vh;
} */

 .rdt_TableHeadRow{
    background-color: #2b303b !important;
    border-radius: 10px !important;
 }
 .rdt_TableHead{
    background-color: #2b303b !important;
    border-radius: 10px !important;
    color: white !important
 }
 .tableContainer{
    box-shadow: rgba(17, 12, 46, 0.15) 5px 5px 34px 5px !important;
    padding: 15px !important;
 }
 .box-shadow{
    box-shadow: rgba(17, 12, 46, 0.15) 5px 5px 34px 5px !important;
 }
 .submitButtons {
   background-color: #18285a !important;
   border-style: none !important;
   padding: 6px !important; 
   /* border-radius: 5px; */
   color: white;

 }
 .submitButtons:hover{
   background-color: #0c1845 !important;
   border-style: none  !important;
 }
 .subdepartment-drop.show{
height: 100 !important;
 }
 .dropdown-toggle.show {
   max-height: 160px; /* Set your preferred maximum height */
  overflow-y: auto; 
  /* text-overflow: ellipsis ; */
  /* width: 100px; */

 }
 .dropdown-menu.show{
   max-height: 160px; /* Set your preferred maximum height */
  overflow-y: auto; 

}
 .dropdown-item {
   padding: 8px;
   cursor: pointer;
   text-overflow: ellipsis ;
 }
 .dropdown-toggle:after {
   content: '\25BC'; /* Unicode for a downward-pointing triangle or your custom arrow */
   /* position: absolute; */
   text-overflow: ellipsis ;
   
   top: 50%;
   right: 10px;
   transform: translateY(-50%);
   pointer-events: none;
 }