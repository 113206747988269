:root {
    --header-height: 3rem;
    --nav-width: 68px;
  
    --first-color: #2b303b;
    --first-color-alt: #f582a7;
    --white-color: white;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  a {
    text-decoration: none;
  }
  
  ul {
    list-style: none;
  }
  
  main {
    position: relative;
    margin: var(--header-height) 0 0 0;
    padding: 0 1rem;
    font-size: 1rem;
    font-family: 'Nunito Sans', sans-serif;
    transition: 0.5s;
  }
  
  .header {
    position: fixed;
    top: 0;
    left: 0;
    height: var(--header-height);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white-color);
    padding: 0 1rem;
    transition: 0.5s;
    box-shadow: 0px 0px 5px 0px black;
  }
  
  .header-toggle {
    font-size: 1.25rem;
    cursor: pointer;
    color: var(--first-color);
  }
  
  .sidebar {
    position: fixed;
    top: 0;
    left: -30%;
    height: 100%;
    width: var(--nav-width);
    background-color: var(--first-color);
    padding-top: 0rem;
    transition: 0.5s;
    overflow: auto; /* Use 'overflow-y' for vertical scrolling */
}

/* .sidebar::-webkit-scrollbar {
  width: 10px;
} */

  .navt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto !important;
    height: 100%;
  }

  .navt::-webkit-scrollbar {
  width: 10px;
  color: #1e2229;
  background-color: gray;
  height: 100%;
  border-radius: 50px;
}
  
  .nav-logo,
  .nav-link {
    display: grid;
    grid-template-columns: max-content max-content;
    column-gap: 2rem;
    padding: 0rem 0 0.5rem 1.0rem;
  }
  
  /* .nav-logo {
    margin-bottom: 2rem;
  } */
  
  .nav-logo-icon {
    font-size: 1.25rem;
    color: var(--white-color);
    cursor: pointer;
  }
  
  .nav-logo-name {
    font-weight: 700;
    color: var(--white-color);
    padding-top: 10px;
  }
  .nav-logo:hover{
    text-decoration: none;
  }
  
  .nav-link {
    position: relative;
    color: white;
    transition: 0.5s;
    margin-bottom: 0.6rem;
  }
  
  /* .nav-link:hover {
    color: var(--white-color);
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .active {
    color: var(--white-color);
    background-color: rgba(0, 0, 0, 0.1);
  } */
  
  /* .active::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 38px;
    width: 3px;
    background-color: var(--white-color);
    transition: 0.5s;
  }
   */
  .show1 {
    left: 0;
  }
  
  .space-toggle {
    padding-left: calc(var(--nav-width) + 1rem);
  }
  
  @media screen and (min-width: 768px) {
    main {
      margin: calc(var(--header-height) + 1rem) 0 0 0;
      padding: 0 1rem 0 calc(var(--nav-width) + 2rem);
    }
  
    .header {
      height: calc(var(--header-height) + 1rem);
      padding-left: calc(var(--nav-width) + 2rem);
    }
  
    .sidebar {
      left: 0;
      padding: 1rem 0 0 0;
    }
  
    .show1 {
      width: calc(var(--header-height) + 156px);
    }
  
    .space-toggle {
      padding-left: calc(var(--nav-width) + 170px);
    }
  }

  .nav-link {
    position: relative;
    color: black;
    transition: 0.5s;
    margin-bottom: 0.6rem;
}

.gradient-custom {
  /* fallback for old browsers */
  background: #f6d365;
  
  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right bottom, rgba(246, 211, 101, 1), rgba(253, 160, 133, 1));
  
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right bottom, #18285a, #2b303b)
  }

